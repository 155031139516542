import React, { useEffect } from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Layout } from "../Layout";

const Container = styled(motion.div)`
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
    grid-gap: 1rem;

    /*   max-width: 600px; */
    /*   padding: 1rem; */
    margin: 0;
  }
  .card {
    background-color: #cc032f2e;
    border: 1px dashed #cc032f42;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 2rem;
    color: white;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
  }

  .card > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0;
      display: flex;
      .col {
        flex: 1;
        &.end {
          border-right: 1px dashed #565355 !important;
        }
        .flag-icon {
          height: 12px;
          width: 15px;
          margin-right: 5px;
        }
        .emp-date {
          display: block;
          font-weight: 700;
          font-size: 17px;
          color: #9a9498;
          margin-top: -3px;
        }
        .com {
          /*   display:flex;
              margin-bottom: 15px; */

          flex-flow: row wrap;
          align-content: space-between;
          justify-content: space-between;
          .line-break {
            flex-basis: 100%;
            height: 0;
          }
          /*  svg{
                width: 13px;
                margin-top: 3px;
              } */
        }
        .com-info {
          flex: 1;
          margin-left: 10px;
          margin-bottom: 30px;

          .title {
            margin-top: 5px;
            font-weight: 700;
            font-size: 17px;
            margin-bottom: 5px;
            a {
              color: white;
              &:hover {
                color: #cc032f;
              }
            }

            svg {
              width: 15px;
            }
            span {
              border-left: 1px solid #cc032f;
              margin: 0 0 10px 10px;
              padding-left: 10px;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 1px;
            }
          }
          .c-name {
            color: #adabaa;
            font-size: 14px;
          }
        }
        &.mLeft- {
          margin-left: 20px;
        }
        &.date {
          padding-right: 20px;
          text-align: right;
          position: relative;
          width: 110px;
          flex: 0 0 110px;
          /* border-right: 1px solid #565355; */
          margin-bottom: 20px;
        }
      }
    }
  }
`;

const dataX = [
  { skill: "React" },
  { skill: "React Native" },
  { skill: "AngularJS" },
  { skill: "Azure" },
  { skill: "AWS" },
  { skill: "Nextjs" },
  { skill: "TailwindCSS" },
  { skill: "Javascript" },
  { skill: "HTML5" },
  { skill: "CSS3" },
  { skill: "GIT" },
  { skill: "Photoshop" },
  { skill: "Figma" },
  { skill: "Webpack" },
  { skill: "Cucumber JS" },
  { skill: "Yarn" },
  { skill: "Linux" },
];

const headerVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 60,
      damping: 10,
      duration: 1,
      ease: [0.6, 0.05, -0.01, 0.9],
    },
  },
  hidden: { opacity: 0 /*  y: -50 */ },
};

const parentVariants = {
  visible: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const childVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Skills = (props) => {
  const animation = useAnimation();
  const animationUL = useAnimation();
  const [contentRef, inView] = useInView({
    rootMargin: "-30px",
    /* triggerOnce: true,
    rootMargin: "-300px", */ // will only effect the scroll margin
  });
  useEffect(() => {
    if (inView) {
      animation.start("visible");
      animationUL.start("visible");
    } else {
      animation.start("hidden");
      animationUL.start("hidden");
    }
  }, [animation, animationUL, inView]);

  return (
    <Layout>
      <Container ref={contentRef}>
        <motion.div
          animate={animation}
          initial="hidden"
          variants={headerVariants}
        >
          <h1>Summary of Skills</h1>
        </motion.div>
        <motion.ul
          animate={animationUL}
          initial="hidden"
          variants={parentVariants}
          className="grid"
        >
          {dataX.map((item, i) => (
            <motion.li key={i} variants={childVariants} className="card">
              {item.skill}
            </motion.li>
          ))}
        </motion.ul>
      </Container>
    </Layout>
  );
};

export default Skills;
