import React from 'react';
import styled from 'styled-components'
import facepaint from 'facepaint'
/* import { Experience, Skills, Projects, Me } from './com' */
import { Amp } from './Amp'
/* import "./index.css"; */
const mq = facepaint([
  '@media(min-width: 420px)',
  '@media(min-width: 920px)',
  '@media(min-width: 1120px)'
])
const Container = styled('div')`
    margin: 0 auto;
    font-family: 'Open Sans', sans-serif;
    ${mq({
  //background: ['red', 'green', 'blue', 'pink'],
  width: ['90%', '80%', '80%', '700px'],

})};

`


function App() {
  return (
    <Container >
      <Amp />
    </Container>
  );
}

export default App;
