import React, { useEffect } from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
/* import FlagIconFactory from 'react-flag-icon-css' */
import { Layout } from "../Layout";
import Item from "./Item";
import facepaint from "facepaint";

const mq = facepaint([
  "@media(min-width: 420px)",
  "@media(min-width: 920px)",
  "@media(min-width: 1120px)",
]);

/* const FlagIcon = FlagIconFactory(React, { useCssModules: false })  */
const Container = styled(motion.div)`
  ${mq({
    ul: {
      li: {
        ".dot": {
          top: ["12px", "15px", "15px", "15px"],
        },
        //background: ['green', 'red', 'yellow', 'right'],
        flexDirection: ["column", "row", "row", "row"],
        ".col": {
          //flex: ['1', '0 0 110px', '0 0 110px', '0 0 110px'],
          "&.mLeft-": {
            marginLeft: ["0", "20px", "20px", "20px"],
            marginTop: ["15px", "5px", "05px", "5px"],
          },
          "&.date": {
            //display: ['none', 'block', 'block', 'block'],
            background: ["#ffffff14", "none", "none", "none"],
            textAlign: ["left", "right", "right", "right"],
            width: ["90%", "110px", "110px", "110px"],
            flex: ["1", "0 0 110px", "0 0 110px", "0 0 110px"],
            padding: ["5px 10px", "", "", ""],
            //paddingRight:['0', '20px', '20px', '20px']
          },
        },
      },
    },
  })};
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0;
      display: flex;
      .dot {
        height: 5px;
        width: 5px;
        background-color: #2f0b31;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        /*  top: 15px; */
        border: 3px solid #cc032f;
        right: -6px;
        margin: auto;
      }
      .col {
        flex: 1;
        &.end {
          border-right: 1px dashed #565355 !important;
        }
        .flag-icon {
          height: 12px;
          width: 15px;
          margin-right: 5px;
        }
        .emp-date {
          display: block;
          font-weight: 700;
          font-size: 17px;
          color: #9a9498;
          margin-top: 2px;
        }
        .com {
          display: flex;
          margin-bottom: 15px;
          svg {
            margin-top: 3px;
          }
          &.grad {
            margin-bottom: 50px;
          }
        }
        .com-info {
          flex: 1;
          margin-left: 10px;
          .title {
            margin-top: 5px;
            font-weight: 700;
            font-size: 17px;
            margin-bottom: 5px;
          }
          .c-name {
            color: #adabaa;
            font-size: 14px;
          }
        }
        .proj-summary {
          display: flex;
          margin: 15px 0 30px;
          .summary {
            flex: 1;
            margin: 0;
            padding: 0;
            list-style: none;
            color: #c5c4c4;
            li {
              margin: 5px 0;
              font-size: 14px;
              line-height: 25px;
              padding-left: 15px;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkE0Q0MzNUJCOEExNDExRTdCNUVBRTRGQjAwMjQ4OUEwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkE0Q0MzNUJDOEExNDExRTdCNUVBRTRGQjAwMjQ4OUEwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTRDQzM1Qjk4QTE0MTFFN0I1RUFFNEZCMDAyNDg5QTAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTRDQzM1QkE4QTE0MTFFN0I1RUFFNEZCMDAyNDg5QTAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5o8iFGAAAAL0lEQVR42mL4//8/AxI+g8xnQJNEUYBNEq6AhYGB4QwDJjABi+PTSZKdKK4FCDAAhIOEGydj8pcAAAAASUVORK5CYII=)
                no-repeat 0 9px !important;
              &.tit {
                font-weight: 700;
                margin-bottom: 15px;
                letter-spacing: 1px;
                font-size: 12px;
                background: none !important;
                color: beige;
              }
            }
          }
          .projects {
            flex: 1;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
              margin: 10px 0;
              font-size: 14px;
              &.tit {
                font-weight: 700;
                margin-bottom: 15px;
                letter-spacing: 1px;
                font-size: 12px;
              }
              a {
                color: #cc032f;
              }
            }
          }
        }
        &.date {
          padding-right: 20px;
          /* text-align: right; */
          position: relative;
          /*  width: 110px; */
          /* flex: 0 0 110px; */
          border-right: 1px solid #565355;
        }
      }
    }
  }
`;
const xp = [
  {
    id: 0,
    company: "Orbital Technologies Inc.",
    position: "Software Engineer",
    employedDate: "2022 - Present",
    location: "ca",
    roles: [
      {
        text: "Contributed to the development of a responsive web and mobile application for an online pharmacy using React Native, TypeScript, Azure and MongoDB, achieving a 30% reduction in code complexity, resulting in a highly efficient and scalable platform that significantly improved user experience and increased customer satisfaction.",
      },
      {
        text: "Led the UX/UI design of React Native application, using Figma, and seamless integration of AWS GraphQL APIs, resulting in a substantial enhancement of both visual aesthetics and user-friendly interaction on various components such as web mapping and search. ",
      },
      {
        text: "Enhanced software quality and reliability by implementing comprehensive test automation strategies, leveraging Jest and React Testing Library.",
      },
      {
        text: "Delivered a full-stack end-to-end development and UX/UI design of a responsive timesheet web application, employing AngularJS, NestJS, MongoDB and Figma, which resulted in an intuitive platform with improved efficiency, reducing time spent on timekeeping tasks by 20%.",
      },
    ],
  },
  {
    id: 1,
    company: "DBS Bank Ltd.",
    position: "Software Engineer",
    employedDate: "2020 - 2021",
    location: "sg",
    roles: [
      {
        text: "Orchestrated the development of a user centric financial planning application using ReactJS, catering to the needs of both bank manager and customers, resulting in a highly customizable and user-friendly financial management platform while harnessing the power of big data.",
      },
      {
        text: "Delivered a series of reusable micro frontend architecture using ReactJS, ReactQuery, d3.js, and HighCharts for seamless integration with existing and future applications, significantly optimizing development efficiency.",
      },
      {
        text: "Implemented unit and end-to-end test automation strategies using Jest and Cypress while developing components with ReactJS, effectively preventing bugs and errors, thus enhancing code quality and project dependability.",
      },
      {
        text: "Earned a stellar reputation among team members and managers as the driving force behind the establishment and promotion of modern technologies, coding standards and best practices, cultivating a unified and efficient development ecosystem.",
      },
      /*{
        text: "Contribute in the architecture of the new applications/projects",
      },
      {
        text: "Implemented unit and End-to-end test automations using Jest and Cypress",
      }, */
    ],
  },
  {
    id: 2,
    company: "Marine Online Pte Ltd.",
    position: "Web Developer",
    employedDate: "2019 - 2020",
    location: "sg",
    roles: [
      {
        text: "Led a comprehensive overhaul of the ecommerce apps' UX/UI design to achieve modern and intuitive navigation using ReactJS, SASS, Figma and Sketch, earning high praise from management: 'What I want is what I see.'",
      },
      {
        text: "Initiated the implementation of web application security testing using BurpSuite on the e-commerce app, gaining enthusiastic support from management and fortifying defenses against recurrence of cyberattacks.",
      },
      {
        text: "Built reusable micro frontend components using ReactJS, WebPack, Yarn and Ant Design, which significantly improved team productivity in terms of development time, bug rates, time-to-market, and scalability.",
      },
      /*  {
        text: "Working on the company’s web based application using HTML5, CSS3, and React",
      },
      { text: "Assisting in Web Application Security testing" }, */
    ],
  },

  {
    id: 3,
    company: "Greenwave systems Inc. ",
    position: "Senior Web Engineer (promoted)",
    employedDate: "2014 - 2019",
    location: "sg",
  },

  {
    id: 4,
    company: "Greenwave systems Inc.",
    position: "Web Engineer",
    employedDate: "2013 - 2014",
    location: "sg",
    roles: [
      {
        text: "Led the design and development of a custom dashboard application by leveraging data from the JIRA Rest API, utilizing AngularJS, which enhanced project visibility and streamlined data-driven decision-making processes.",
      },
      {
        text: "Initiated and spearheaded the design and development of proof-of-concept (POC) initiatives, including a responsive home automation app, using AngularJS, XML, JSON and XMPP/Rest services, leading to a 20% increase in interest from stakeholders and paving the way for the implementation of usable, accessible and modern IoT technology.",
      },
      {
        text: "Championed the delivery of web applications, harnessing HTML5, CSS3, Javascript/jQuery, AngularJS and ReactJS to drive innovative solutions and enhance user experiences.",
      },
      /* {
        text: "Efficiently developed and improved website and web Application functionalities  by using XML or Json and Jquery /Javascript",
      },
      {
        text: "Developed Hybrid mobile applications and native mobile applications.",
      },
      {
        text: "Working on companies web based applications  using  HTML5, CSS3, javascript/jquery and Angularjs/ReactJS",
      }, */
    ],
  },

  {
    id: 5,
    company: "NCS Pte. Ltd.",
    position: "UI  Developer / Assistant Web Producer",
    employedDate: "2012 - 2013",
    location: "sg",
    roles: [
      {
        text: "Lead UI developer responsible in developing  websites to HTML5 and Created JavaScript  plugins  for the Interactive Flashcards and Case studies.",
      },
      {
        text: "Developed sites using the latest technology HTML5 and CSS3 while ensuring that sites are Standards-compliant/cross-browser compatible.",
      },
      {
        text: "Managed projects efficiently by setting deadlines at a realistic yet timely manner",
      },
      {
        text: "Efficiently developed and improved website functionalities  by using XML or Json and Jquery /Javascript",
      },
      {
        text: "Worked on migrating the interactive Question and Answers  into a mobile application using html5 localstorage",
      },
      { text: "Create web designs according to the Books Cover" },
      { text: "Working in Agile Software  Application Development Process" },
    ],
  },
  {
    id: 6,
    company: "The Design People",
    position: "Senior Front end Developer/Assistant Team Lead",
    employedDate: "2006 - 2012",
    location: "us",
    roles: [
      { text: "Assistant to the Team Lead" },
      {
        text: "Innovated technologies and techniques that speeds up front-end web development and AS3 scripting",
      },
      { text: "Refined designs that are to be converted into Flash AS3" },
      {
        text: "Managed the Integration of Flash into non-Flash portions of web site",
      },
      {
        text: "Optimized Flash files and web content before upload to the remote server ",
      },
      { text: "Implemented Object-Oriented Programming on AS3 projects" },
    ],
  },
  /*{
    id: 7,
    company: "Dreamsoft Business Solutions",
    position: "User  Interface Designer",
    employedDate: "2005 - 2006",
    location: "ph",
    roles: [
      { text: "Designed GUI using Photoshop, Fireworks and CorelDraw" },
      {
        text: "Translated client storyboards into  flash quizzes  and animated presentations.",
      },
      { text: "Created various storyboards for the clients" },
    ],
  },
    {
    id: 8,
    company: "Freelance ",
    position: "Software Developer/Designer",
    employedDate: "2003 - 2005",
    location: "ph",
    roles: [
      { text: "Lan Configuration and Setup" },
      { text: "Develop Various desktop applications for SME`s" },
    ],
  }, */
  {
    id: 9,
    company: "Bachelor of Science in Information Technology",
    position: "Nueva Ecija University of Science and Technology",
    //employedDate: "2003",
    location: "ph",
    start: true,
  },
];

const headerVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 160,
      damping: 120,
      duration: 0.8,
      ease: [0.6, 0.05, -0.01, 0.9],
    },
  },
  hidden: { opacity: 0 },
};

const Experience = (props) => {
  const animation = useAnimation();
  const [contentRef, inView] = useInView({
    //rootMargin: "-200px",
    /* triggerOnce: true,
    rootMargin: "-300px", */
    // will only effect the scroll margin
  });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    } else {
      animation.start("hidden");
    }
  }, [animation, inView]);
  return (
    <Layout>
      <Container ref={contentRef}>
        <motion.div
          animate={animation}
          initial="hidden"
          variants={headerVariants}
        >
          <h1>Work Experience</h1>
        </motion.div>
        <motion.ul /* animate={animationUL} initial="hidden" variants={headerVariants} */
        >
          {xp.map((item, i) => (
            <Item key={i} idx={i} item={item} />
          ))}
        </motion.ul>
      </Container>
    </Layout>
  );
};

export default Experience;
