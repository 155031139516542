import React, { useEffect, useState /* , useRef, useCallback  */} from 'react';
import styled from 'styled-components'
/* import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import SchoolIcon from '@material-ui/icons/School'; */
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
/* import FlagIconFactory from 'react-flag-icon-css' */


const Container = styled(motion.svg)`

  width: 56%;
  overflow: visible;
  stroke: #da1c5c;
  stroke-width: 5;
/*   stroke-linejoin: round;
  stroke-linecap: round; */


`


const icon = {
  hidden: {
    pathLength: 0,
    fill: "#000",
    opacity:0,
  },
  visible: {
    pathLength: 1,
    fill: "#da1c5c",
    opacity:1,
    transition:{
      default: {duration:1, ease: "easeInOut" },
      fill: { duration: 2, ease: [1, 0, 0.8, 1] }
    }
  }
}

const ChLogo = props => {
 /*  const { isComplete } = props */
 const [isTap, setIsTap] = useState(0);
 /* const [isDrag, setIsDrag] = useState({ left: 0, right: 0, bottom: 0, top: 0 }); */
  const animation = useAnimation()

  const [contentRef, inView] = useInView({
    //rootMargin: '-40px'
  })

    useEffect(() => {
      if (inView) {
        animation.start('visible')
      }
    }, [animation, inView])
    const draggy = { left: 0, right: 0, bottom: 0, top: 0 }

    const onTap = (event, info)  => {
      setIsTap(isTap + 1);
      console.log(isTap)
      if (isTap === 3){
        //draggy
        
      }
     
    }

    

  return (
    <Container ref={contentRef} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.79 275.78" drag
      dragConstraints={draggy} onTap={onTap}>
        <motion.path
          d="M126,184.26a54.37,54.37,0,1,1-29.54-100c1.58,0,3.15.09,4.7.22h.49a12.48,12.48,0,0,1-.49,24.94h-.56a28.31,28.31,0,0,0-4.14-.34A29.55,29.55,0,1,0,126,138.64v-1.49h0V2.88A85.94,85.94,0,0,0,77.23,27.23l-49.5,49.5a86.76,86.76,0,0,0,0,122.33l49.5,49.49A85.94,85.94,0,0,0,126,272.9Z"
          variants={icon}
          initial="hidden"
          animate={animation}
          
        />
        <motion.path
          d="M249.05,76.73l-49.49-49.5A86,86,0,0,0,150.82,2.88V91.52a54.34,54.34,0,0,1,83.93,45.62v76.22l14.3-14.3A86.74,86.74,0,0,0,249.05,76.73Z"
          variants={icon}
          initial="hidden"
          animate={animation}
          
        />
        <motion.path
          d="M209.9,204.92v-67h0c0-.25,0-.49,0-.75a29.54,29.54,0,1,0-59.08,0V272.9a86,86,0,0,0,48.74-24.35l10.34-10.34Z"
          variants={icon}
          initial="hidden"
          animate={animation}
         />
    </Container>
  )
}

export default ChLogo


