import React from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Layout } from "../Layout";

import facepaint from "facepaint";

const mq = facepaint([
  "@media(min-width: 420px)",
  "@media(min-width: 920px)",
  "@media(min-width: 1120px)",
]);

/* import { device } from "../device"; */

const Container = styled(motion.div)`
  ${mq({
    ".feat": {
      display: "flex",
      flexDirection: ["column", "row", "row", "row"],
    },
    ".info-wrapper": {
      alignSelf: ["center", "center", "center", "center"],
      marginLeft: ["0", "20px", "20px", "20px"],
    },
    ".tinda-feat": {
      width: ["100%", "50%", "50%", "50%"],
    },
  })};

  .tools {
    font-size: 0.75em;
    color: rgb(140, 142, 142);
    font-family: "Open Sans", sans-serif;
  }
  .info {
    font-size: 1em;
    color: #fff;
    font-family: "Open Sans", sans-serif;
  }

  .featured-img {
    background-image: url("./featured.png");
    height: 300px;
    width: 300px;
  }
  .cv {
    margin-bottom: 50px;
  }
`;

const itemVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 160,
      damping: 120,
      duration: 0.8,
      ease: [0.6, 0.05, -0.01, 0.9],
    },
  },
  hidden: { opacity: 0, y: -100 },
};

const Me = (props) => {
  const animation = useAnimation();
  const [contentRef, inView] = useInView({
    rootMargin: "-30px",
    /* triggerOnce: true,
    rootMargin: "-300px", */ // will only effect the scroll margin
  });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    } else {
      animation.start("hidden");
    }
  }, [animation, inView]);

  return (
    <Layout>
      <Container ref={contentRef}>
        {/* <div style={{position:'fixed', top:0}}>{JSON.stringify(props.isNum)}</div> */}
        <motion.div
          animate={animation}
          initial="hidden"
          variants={itemVariants}
        >
          <h1 className="meme">
            Hi, I am Erwin Joseph {/* `${device.mobileS}` */}
            <div className="cv">
              <a href="./myresume.docx" style={{ color: "white" }}>
                <span>Download latest CV</span>
                <GetAppIcon fontSize="small" />
              </a>
            </div>
          </h1>
          <h1>Featured Project</h1>
          <div className="feat">
            <img src="./featured.png" alt="Tinda" className="tinda-feat" />
            <div className="info-wrapper">
              <img src="./tinda-logo.png" alt="Tinda" />
              <p className="info">
                Discover local treasures and chat with sellers on our mobile
                marketplace app. Shop unique products, connect with small
                businesses, and enjoy a personalized shopping experience, all in
                one place.
              </p>
              <p className="tools">
                React Native, Mapbox, Pubnub, Aws-amplify, GraphQL, DynamoDB,
                AppSync, Cognito, s3
              </p>
            </div>
          </div>
        </motion.div>
      </Container>
    </Layout>
  );
};

export default Me;
