import React from 'react'
import facepaint from 'facepaint'
import styled from 'styled-components'
import { motion /* useAnimation */ } from "framer-motion";
/* import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { device } from "../device"; */

const mq = facepaint([
  '@media(min-width: 420px)',
  '@media(min-width: 920px)',
  '@media(min-width: 1120px)'
])



const Container = styled(motion.div)`
  color: beige;
  h1{
    font-size: 15px;
    font-family: 'Poppins',sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 30px;
    &.meme{
      margin:0;
      text-transform: capitalize;
      .cv{
        a:hover{
          color:#cc032f
        }
        a{
          display:flex;
        font-size: 12px;
        color: #8c8e8e;
        font-family: 'Open Sans',sans-serif;
        letter-spacing: 0px;
        text-decoration:none;
          .MuiSvgIcon-fontSizeSmall{
            font-size: 18px;
          }
        }
        
      }
    }
    
  }
  ${mq({
      marginBottom: ['0', '0', '0', '0'],
      marginTop: ['30px', '50px', '50px', '50px'],
       h1: {
      '&.meme': {
        fontSize:  ['20px', '25px', '50px', '50px']
     }
    }
  })};

`

const Layout = (props) => {
  return (
    <Container>
      {props.children}
    </Container>
  )
}

export default Layout
