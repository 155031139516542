import * as React from "react";
import facepaint from 'facepaint'
import { useEffect, useState } from "react";
import styled from 'styled-components'
import ChLogo from "./com/ChLogo";

import {
  motion,
  useViewportScroll,
/*   useSpring, */
  useTransform
} from "framer-motion";

import { Experience, Skills, Projects, Me, Tools } from './com'

const mq = facepaint([
  '@media(min-width: 420px)',
  '@media(min-width: 920px)',
  '@media(min-width: 1120px)'
])

const Container = styled(motion.div)`

${mq({
    '.progress-icon': {
      top: '20px',
      width: '120px',
      height: '120px',
      position:'fixed',
      left: ['', '20px', '20px', '20px'],
      right: ['-10px', '', '', ''],
    }
})};


.progreso{
  width:100%;
  position:fixed;
  left:0;
  height:7px;
  top:0;
  z-index: 1;
  background:#ffffff47;
  .proggy{
    height: 7px;
    background:#c9355d;
  }
}
`

const headerVariants = {
  visible: {
    opacity: 1,
    y: 0,
    x: 0, 
    transition: { type: "spring", stiffness: 160, damping: 120, duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] },
  },
  hidden: { opacity: 0 , x: -100 ,transition: { type: "spring", stiffness: 160, damping: 120, duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }, },
};



export const Amp = (props) => {
  const [isComplete, setIsComplete] = useState(false);
  const [isNum, setIsNum] = useState(0);

  const { scrollYProgress, /* scrollXProgress */ } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
  //const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  /* 
    const width = useTransform(
      scrollYProgress,
      [0,   ],
      ["calc(0% - 0px)", "calc(100% - 40px)"]
  ) 
  */


  useEffect(() => yRange.onChange(v => {
    setIsNum(`${Math.ceil(v * 100)}%`);
    setIsComplete(v >= 1);
  }
  ), [yRange]);

  return (
    <Container>
      <div className="progreso">
        <motion.div
          className="proggy"
          initial={{ /* scale: 0,  */ width: 0 }}
          animate={{ /* rotate: 180, scale: 1,  */width: isNum }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 120
          }}
        />
      </div>

      {/*   <div style={{position:'fixed', color:'red', left:0,background:'white', width:isNum}}>{JSON.stringify(isNum)}</div> */}

      <Me isNum={isNum} />
      <Tools />
      <Skills />
      <Projects />
      <Experience />
      <motion.div className="progress-icon" animate={isComplete ? 'visible' : 'hidden'} initial="hidden" variants={headerVariants}>
        <ChLogo />
      </motion.div>
    </Container>
  );
};
