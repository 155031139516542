import React, { useEffect /* , useRef, useCallback  */ } from "react";
/* import styled from 'styled-components' */
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import SchoolIcon from "@material-ui/icons/School";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import FlagIconFactory from "react-flag-icon-css";

const FlagIcon = FlagIconFactory(React, { useCssModules: false });
const childVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Item = (props) => {
  const { idx, item } = props;
  const animation = useAnimation();

  const [contentRef, inView] = useInView({
    rootMargin: "-40px",
  });

  useEffect(() => {
    /* console.log(idx); */
    if (inView) {
      animation.start("visible");
    } else {
      animation.start("hidden");
    }
  }, [animation, inView]);
  return (
    <motion.li
      animate={animation}
      ref={contentRef}
      initial="hidden"
      variants={childVariants}
    >
      <div className={"col date " + (item.start ? "end" : "")}>
        <span className="emp-date">{item.employedDate}</span>
        <div className="dot"></div>
      </div>
      <div className="col mLeft-">
        <div className={idx === 7 ? "com grad" : "com"}>
          {item.start ? <SchoolIcon /> : <WorkOutlineIcon />}{" "}
          <div className="com-info">
            <p className="title">{item.position}</p>
            <span className="c-name">
              <FlagIcon code={item.location} /> {item.company}
            </span>
          </div>
        </div>
        {item.roles && (
          <div className="proj-summary">
            <ul className="summary">
              <li className="tit">ROLES</li>
              {item.roles.map((role, i) => (
                <li key={i}>{role.text}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </motion.li>
  );
};

export default Item;
