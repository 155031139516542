import React from "react";
import styled from "styled-components";
import LanguageIcon from "@material-ui/icons/Language";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { Layout } from "../Layout";
import facepaint from "facepaint";

const mq = facepaint([
  "@media(min-width: 420px)",
  "@media(min-width: 920px)",
  "@media(min-width: 1120px)",
]);

const headerVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 160,
      damping: 120,
      duration: 0.8,
      ease: [0.6, 0.05, -0.01, 0.9],
    },
  },
  hidden: { opacity: 0 },
};

const parentVariants = {
  visible: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const childVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Container = styled(motion.div)`
  ${mq({
    /* 
   background: ['red', 'green', 'blue', 'pink'],
   width: ['', '80%', '', ''], */

    ul: {
      li: {
        flexDirection: ["column", "row", "row", "row"],
        ".col": {
          flex: ["1", "0 0 110px", "0 0 110px", "0 0 110px"],
          "&.mLeft-": {
            marginLeft: ["0", "20px", "20px", "20px"],
          },
          "&.date": {
            //display: ['none', 'block', 'block', 'block'],
            background: ["#ffffff14", "none", "none", "none"],
            textAlign: ["left", "right", "right", "right"],
            width: ["90%", "110px", "110px", "110px"],
            flex: ["1", "0 0 110px", "0 0 110px", "0 0 110px"],
            padding: ["5px 10px", "", "", ""],
          },
        },
      },
    },
  })};
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0;
      display: flex;
      .col {
        flex: 1;
        &.end {
          border-right: 1px dashed #565355 !important;
        }
        .flag-icon {
          height: 12px;
          width: 15px;
          margin-right: 5px;
        }
        .emp-date {
          /*  display: block; */
          font-weight: 700;
          font-size: 17px;
          color: #9a9498;
          margin-top: 10px;
        }
        .com {
          /*   display:flex;
              margin-bottom: 15px; */

          flex-flow: row wrap;
          align-content: space-between;
          justify-content: space-between;
          .line-break {
            flex-basis: 100%;
            height: 0;
          }
          /*  svg{
                width: 13px;
                margin-top: 3px;
              } */
        }
        .com-info {
          flex: 1;
          margin-left: 10px;
          margin-bottom: 30px;

          .title {
            margin-top: 5px;
            font-weight: 700;
            font-size: 17px;
            margin-bottom: 5px;
            a {
              color: white;
              &:hover {
                color: #cc032f;
              }
            }

            svg {
              width: 15px;
            }
            span {
              border-left: 1px solid #cc032f;
              margin: 0 0 10px 10px;
              padding-left: 10px;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 1px;
            }
          }
          .c-name {
            color: #adabaa;
            font-size: 14px;
          }
        }

        &.date {
          padding-right: 20px;
          /*  text-align: right; */
          /* position:relative; */
          /* width: 110px;
                 flex: 0 0 110px; */
          border-right: 1px solid #565355;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

const data = [
  {
    id: 0,
    company: "Orbital Technologies Inc.",
    works: [
      {
        desc: "Online pharmacy web and mobile app built in React Native and TypeScript",
        what: "MyPrescyptive",
        whatSpan: "web",
        link: "https://myprescryptive.com/",
      },
      {
        desc: "Application built in for Microsoft for managing clients and transactions, includes online document sign and receipt generation. Using React, typescript, Redux toolkit.",
        what: "Microsoft Agreement Center",
        whatSpan: "internal",
      },
      {
        desc: "An online product management for Mayekawa, incorporating a custom dashboard with advanced features like precise wattage and load balancing data visualizations. Developed using Angular, TypeScript, Highcharts, and Figma.",
        what: "MySelect | Mayekawa",
        whatSpan: "web",
        link: "https://myselect.mayekawausa.com",
      },
    ],
  },

  {
    id: 1,
    company: "DBS bank Ltd.",
    works: [
      {
        desc: "Application developed with React for real-time processing of electronic banking transactions",
        what: "Client Connect",
        whatSpan: "internal",
      },
      {
        desc: "Application built in React for bank manager and customers in planning their finances online",
        what: "Your financial journey",
        whatSpan: "internal",
      },
    ],
  },
  {
    id: 2,
    company: "Marine Online Pte Ltd.",
    works: [
      {
        desc: "Online shopping platform that utilizes ReactJS, Webpack, and Ant Design",
        what: "Marine Online",
        whatSpan: "web",
        link: "https://www.marineonline.com",
      },
      {
        desc: "Job search platform for maritime industry using ReactJS, Webpack, and Ant Design",
        what: "Crewing Online",
        whatSpan: "web",
        link: "https://www.ecrewingonline.com",
      },
    ],
  },
  {
    id: 3,
    company: "Greenwave Systems Inc.",
    works: [
      {
        desc: "A Customized Reponsive Web Application that uses the JIRA API and added extra features",
        what: "Greenwave Systems Dashboard",
        whatSpan: "internal",
      },
      {
        desc: "A HYBRID Mobile  Application using  Cordova-IONIC-angularjs Platform, used to trace the the location of smart Pins",
        what: "Tracie Tracker App",
        whatSpan: "POC",
      },
      {
        desc: "An Admin panel for Verizon Routers, Developed using Angular JS",
        what: "Verizon Router Local App",
        whatSpan: "internal",
      },
    ],
  },
  {
    id: 4,
    company: "CreativeCH",
    works: [
      {
        desc: "My personal portfolio, Developed using Angularjs and Material Design",
        what: "Personal Portfolio",
        whatSpan: "web",
        link: "https://creativech.applibrium.com",
      },
    ],
  },
];

const Projects = (props) => {
  const animation = useAnimation();
  const animationUL = useAnimation();
  const [contentRef, inView] = useInView({
    //rootMargin: "-200px",
    /* triggerOnce: true,
    rootMargin: "-300px", */
    // will only effect the scroll margin
  });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
      animationUL.start("visible");
    } else {
      animation.start("hidden");
      animationUL.start("hidden");
    }
  }, [animation, animationUL, inView]);
  return (
    <Layout>
      <Container ref={contentRef}>
        <motion.div
          animate={animation}
          initial="hidden"
          variants={headerVariants}
        >
          <h1>Latest Projects</h1>
        </motion.div>
        <motion.ul
          animate={animationUL}
          initial="hidden"
          variants={parentVariants}
        >
          {data.map((item, i) => (
            <motion.li variants={childVariants} key={i}>
              <div className={"col date " + (item.start ? "end" : "")}>
                <span className="emp-date">{item.company}</span>
              </div>
              <div className="col mLeft-">
                <div className="com">
                  {item.works &&
                    item.works.map((desc, j) => (
                      <React.Fragment key={j}>
                        <div className="com-info">
                          <p className="title">
                            {desc.link ? (
                              <a
                                href={desc.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {desc.what}
                              </a>
                            ) : (
                              desc.what
                            )}{" "}
                            {desc.whatSpan && desc.whatSpan !== "web" && (
                              <span>{desc.whatSpan.toUpperCase()}</span>
                            )}{" "}
                            {desc.whatSpan === "web" && <LanguageIcon />}
                          </p>
                          <span className="c-name"> {desc.desc}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  <div className="line-break"></div>
                </div>
              </div>
            </motion.li>
          ))}
        </motion.ul>
      </Container>
    </Layout>
  );
};

export default Projects;
