import React from "react";
import styled from "styled-components";
/* import LanguageIcon from '@material-ui/icons/Language'; */
import { Layout } from "../Layout";
import { useInView } from "react-intersection-observer";
import facepaint from "facepaint";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { SVG } from "../SVG/SVG";
const mq = facepaint([
  "@media(min-width: 420px)",
  "@media(min-width: 920px)",
  "@media(min-width: 1120px)",
]);
/*  color: beige;
   margin-bottom: 100px;
   h1{
    font-size: 15px;
    font-family: 'Poppins',sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 30px;
  } */
const Container = styled(motion.div)`
  ${mq({
    /* 
   background: ['red', 'green', 'blue', 'pink'],
   width: ['', '80%', '', ''], */

    ".grid": {
      margin: 0,
      padding: 0,
      listStyle: "none",
      display: "flex",
      justifyContent: "space-between",
      li: {
        background: "#0000003b",
        borderRadius: "20px",
        padding: "10px",
        svg: {
          width: ["30px", "50px", "100px", "100px"],
          height: ["30px", "50px", "100px", "100px"],
        },
      },
    },
  })};

  /* 
   .grid{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
  li{
    background: #0000003b;
    border-radius: 20px;
    padding: 10px;
    svg{
      width:100px;
      height:100px;
    }
  } 
   } */
`;

const headerVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 160,
      damping: 120,
      duration: 0.8,
      ease: [0.6, 0.05, -0.01, 0.9],
    },
  },
  hidden: { opacity: 0, y: -100 },
};

const parentVariants = {
  visible: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const childVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Tools = (props) => {
  const animation = useAnimation();
  const animationUL = useAnimation();
  const [contentRef, inView] = useInView({
    rootMargin: "-30px",
    /* triggerOnce: true,
    rootMargin: "-300px", */ // will only effect the scroll margin
  });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
      animationUL.start("visible");
    } else {
      animation.start("hidden");
      animationUL.start("hidden");
    }
  }, [animation, animationUL, inView]);

  return (
    <Layout>
      <Container ref={contentRef}>
        <motion.div
          animate={animation}
          initial="hidden"
          variants={headerVariants}
        >
          <h1>Tools im currently Using</h1>
        </motion.div>
        <motion.ul
          animate={animationUL}
          initial="hidden"
          variants={parentVariants}
          className="grid"
        >
          <motion.li
            whileHover={{ scale: 1.1 }}
            variants={childVariants}
            title="React/React native"
          >
            {SVG.iconReact}
          </motion.li>
          <motion.li
            whileHover={{ scale: 1.1 }}
            variants={childVariants}
            title="Git"
          >
            {SVG.iconGIT}
          </motion.li>
          <motion.li
            whileHover={{ scale: 1.1 }}
            variants={childVariants}
            title="Figma"
          >
            {SVG.iconFigma}
          </motion.li>
          <motion.li
            whileHover={{ scale: 1.1 }}
            variants={childVariants}
            title="Typescript"
          >
            {SVG.iconTs}
          </motion.li>
          <motion.li
            whileHover={{ scale: 1.1 }}
            variants={childVariants}
            title="Webpack"
          >
            {SVG.iconWebpack}
          </motion.li>
        </motion.ul>
      </Container>
    </Layout>
  );
};

export default Tools;
